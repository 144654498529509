<template>
    <div id="deviceFunSystem">
        <div class="bg-color-black">
            <div style="padding:10px;display:flex;flex-direction:row;align-items: center;">
          <span>
            <icon name="chart-line" class="text-icon"></icon>
          </span>
          <span style="margin-left:5px;margin-right:10px;">系统控制</span>
          <dv-decoration-3 class="dv-dec-1" />
        </div>

        <el-scrollbar class="list">
        <div style="display:flex;flex-direction: row;justify-content: space-between;align-items: flex-start;">

          
          <ul>
            <li v-for="(item, index) in curData" :key="index">
              <div class="list-item display-row">
                <span>{{item.name}}</span>
                <div class="display-row" style="margin-left:30px;">
                    <span style="margin-right:10px;">开关 : </span>
                    <el-switch
                        v-model="item.switch"
                        active-color="#257dff"
                        inactive-color="#666"
                        @change="switchChangeEvent(item.id)">
                    </el-switch>
                </div>
              </div>
            </li>
          </ul>
          <div class="list-item display-row" :style="{display:controlWindDisplay}">
            <span>风扇</span>
            <div class="display-row" style="margin-left:30px;">
                    <span style="margin-right:10px;">开关 : </span>
                    <el-switch
                        v-model="controlWind"
                        active-color="#257dff"
                        inactive-color="#666"
                        @change="switchChangeEvent(0)">
                    </el-switch>
                </div>
          </div>
        </div>
        </el-scrollbar>

        <div class="fun-tip" :style="{display:tipdisplay}">
            <span style="color:#d3d6dd;">{{tipText}}</span>
        </div>
        </div>
    </div>

</template>
<script>

import Transfer from "../utils/transfer.js";

export default {
  data() {
    return {
      //定时器
      numInternal:null,
      numInternalCount: 0, //定时计数器
      //设备参数
      curMachine: '',
      curOnline: 0, //0:离线，1：在线
      curStyleSize: null,  //1：智灵钻， 2：智立方， 3：智立方L， 4：单面屏风， 5：双面屏风， 6：智灵宝， 7：工程模组， 8：集装箱
      curProtocolType: null, //1：家用机协议，2：工程模组协议
      curData: [
        {id: 1, switch: false, name:"水泵1"},
        {id: 2, switch: false, name:"水泵2"},
        {id: 3, switch: false, name:"水泵3"},
        {id: 4, switch: false, name:"水泵4"},
        {id: 5, switch: false, name:"水泵5"},
        {id: 6, switch: false, name:"水泵6"},
        {id: 7, switch: false, name:"水泵7"},
        {id: 8, switch: false, name:"水泵8"},
        {id: 9, switch: false, name:"水泵9"},
        {id: 10, switch: false, name:"水泵10"},
        {id: 11, switch: false, name:"主水泵1"},
        {id: 12, switch: false, name:"主水泵2"},
      ],
      curDataRefreshFlag: false,  //设备数据刷新标志，false：未刷新  true：已刷新， 用于防止数量更新异步操作
      controlWind: false,  //一代机  排风控制风扇
      controlWindDisplay: "flex",
      tipdisplay: "none",
      tipText: ''
      
    }
  },
  components: {

  },
  mounted() {
    Transfer.$on('deviceFunSystemStyle', (msg) => {
      Transfer.$off('deviceFunSystemStyle');
      
      this.curMachine = msg.curMachine;
      this.curOnline = msg.curOnline;
      this.curStyleSize = msg.curStyleSize;
      this.curProtocolType = msg.curProtocolType;
      
      if(this.curProtocolType == 2) {
        if(this.curStyleSize != 7 && this.curStyleSize != 8) {
          this.controlWindDisplay = "none";

          var pumpData = [];
          pumpData[0] = {};
          pumpData[0].id = 1;
          pumpData[0].switch = false;
          pumpData[0].name = "水泵";
          
          this.curData = pumpData;
        }
      } else {
        var pumpData = [];
        pumpData[0] = {};
        pumpData[0].id = 1;
        pumpData[0].switch = false;
        pumpData[0].name = "水泵";

        this.curData = pumpData;
      }
      
    });
    Transfer.$on('deviceFunSystem', (msg) => {
      Transfer.$off('deviceFunSystem');
      // console.log(JSON.stringify(msg));
      this.curMachine = msg.curMachine;
      this.curOnline = msg.curOnline;
      this.curStyleSize = msg.curStyleSize;
      this.curProtocolType = msg.curProtocolType;
      this.curData = msg.curData;
      
      if(msg.controlWind == undefined) {
        this.controlWindDisplay = "none";
      } else {
        this.controlWind = msg.controlWind;
      }

      setTimeout(()=>{
        this.curDataRefreshFlag = true;
      },100);

    });

    Transfer.$on('deviceFunSystemNum', (msg) => {
      Transfer.$off('deviceFunSystemNum');
      // console.log(JSON.stringify(msg));

      //定时检测 设备数据释放刷新
      this.numInternal = setInterval(()=>{
        this.numInternalCount++;
        //检测定时时间，超时，推出定时检测
        if(this.numInternalCount > 1000) {
          this.numInternalCount = 0;
          //清除定时器
          clearInterval(this.numInternal);
          this.numInternal = null;
          return;
        }

        //设备数据已刷新，刷新数量
        if(this.curDataRefreshFlag == true) {
          var pumpExistsArr = msg.pumpExistsArr;
          var toData = [];
          var j = 0;
          for(var i = 0; i < this.curData.length; i++) {
            var id = this.curData[i].id;
            if(pumpExistsArr[id - 1] == true) {
              toData[j++] = this.curData[i];
            }
          }
          this.curData = toData;


          this.numInternalCount = 0;
          //清除定时器
          clearInterval(this.numInternal);
          this.numInternal = null;
        }
        
      }, 10);
      
    });
  },
  beforeDestroy () {
    if(this.numInternal != null) {
      clearInterval(this.numInternal);
      this.numInternal = null;
    }
  },
  methods: {
    
    switchChangeEvent(index) {
      if(this.curOnline == 0) {
        this.tipShowFun(this, "设备离线，无效控制");
        return;
      }

      //1:一代机，2：二代机
      if(this.curProtocolType == 2) {
        //二代机没有排风
        this.controlWindDisplay = "none";
        
        var switchV = this.curData[index-1].switch == true ? 2 : 1;
        if(this.curStyleSize == 7 || this.curStyleSize == 8) {
          
          var column = "pump" + index;
          this.funModulePumpSwitchChange(column, switchV);
        } else {

          var column = "pumpSwitch";
          this.funModuleControlChange(column, switchV);
        }
        
      } else {
        //一代机
        var column = "";
        var switchV = false;
        if(index == 0) {
          column = "controlWind";
          switchV = this.controlWind == true ? 1 : 0;
        } else {
          var switchV = this.curData[index-1].switch == true ? 1 : 0;
          column = "controlPump";
        }
        

        this.funMachineValueChange(column, switchV);
      }
    },

    //更改光强  一代机
    funMachineValueChange(column, value) {
      let that = this;
      
      var reqData = "?id=" + this.curMachine + "&column=" + column + "&value=" + value;
      this.$httpApi.updateMachine(reqData, function(data) {
          // console.log(JSON.stringify(data));
          //成功
          var msg = "";
          if(data == true) {
            msg = "修改成功";
          } else {
            msg = "修改失败";
          }
          that.tipShowFun(that, msg);

      }, function(xhr) {
        that.tipShowFun(that, "修改异常");
        
      });

    },
    //更改水泵  二代机 家用机
    funModuleControlChange(column, value) {
      let that = this;
      
      var reqData = "?machineId=" + this.curMachine + "&column=" + column + "&value=" + value;
      this.$httpApi.updateModuleControl(reqData, function(data) {
          // console.log(JSON.stringify(data));
          //成功
          var msg = "";
          if(data.message == true) {
            msg = "修改成功";
          } else {
            msg = "修改失败";
          }
          that.tipShowFun(that, msg);

      }, function(xhr) {
        that.tipShowFun(that, "修改异常");
        
      });

    },
    //更改水泵  二代机 工程模组
    funModulePumpSwitchChange(column, value) {
      let that = this;
      
      var reqData = "?machineId=" + this.curMachine + "&column=" + column + "&value=" + value;
      this.$httpApi.updateMachineModulePumpSwitch(reqData, function(data) {
          // console.log(JSON.stringify(data));
          //成功
          var msg = "";
          if(data == true) {
            msg = "修改成功";
          } else {
            msg = "修改失败";
          }
          that.tipShowFun(that, msg);

      }, function(xhr) {
        that.tipShowFun(that, "修改异常");
        
      });

    },
    //消息提示
    tipShowFun(that, txt) {
      that.tipText = txt;
      that.tipdisplay = "block";
      setTimeout(() => {
        that.tipdisplay = "none";
        that.tipText = '';
      }, 1000);
    },
  }

}
</script>
<!-- <style lang="scss" scoped> -->
<style lang="scss">

#deviceFunSystem {
    $box-width: 450px;
    $box-height: 900px;
    padding: 16px;
    height: $box-height;
    min-width: $box-width;
    border-radius: 10px;

    //取消横向滚动条  style不能scoped， 否则不起作用
    .el-scrollbar__wrap
    {
        overflow-x: hidden;
    }
    
    .bg-color-black {
        height: $box-height - 30px;
        border-radius: 10px;
    }
    
    .list {
        height: $box-height - 80px;
        margin-top:10px;
    }
    .list-item {
        padding:15px 20px;
    }

    .display-row {
        display:flex;
        flex-direction: row;
        justify-content:space-between;
        align-items: center;
    }

    .dv-dec-1 {
        position: relative;
        width: 100px;
        height: 20px;
        top: -3px;
    }

    .fun-tip {
      position:absolute;
      left:40%;
      bottom:20px;
      padding:15px 50px;
      background-color: rgba(19, 25, 47, 0.6);
      border:2px solid rgb(46, 96, 153);
      border-radius:12px;
    }
}
</style>

