<template>
    <div style="width:360px;height:410px" ref="chartsDOM"></div>
</template>

<script>

import * as echarts from 'echarts';
import getHeBeiMap from "../api/getHeBeiMap.js";

export default {
  data() {
    return {
        
    };
  },
  mounted () {
    // 初始化统计图对象
    var myChart = echarts.init(this.$refs["chartsDOM"]);
    // 显示 loading 动画
    myChart.showLoading();
    // 再得到数据的基础上，进行地图绘制
    getHeBeiMap.then(res => {
        var registerMapName = "河北省";
        var province = registerMapName;
        var city = "石家庄市";
        var cityCoord = ['114.502461', '38.045474'];
        var  mapDatas1 = [
          {name: '石家庄市', value: 10},
          {name: '唐山市', value: 9},
          {name: '秦皇岛市', value: 8},
          {name: '邯郸市', value: 7},
          {name: '邢台市', value: 6,},
          {name: '保定市', value: 5},
          {name: '张家口市', value: 4},
          {name: '承德市', value: 3},
          {name: '沧州市', value: 2},
          {name: '廊坊市', value: 1},
          {name: '衡水市', value: 0},
        ];

        var mapDatas = [
          {name: '石家庄市', value: '130100'},
          {name: '唐山市', value: '130200'},
          {name: '秦皇岛市', value: '130300'},
          {name: '邯郸市', value: '130400'},
          {name: '邢台市', value: '130500'},
          {name: '保定市', value: '130600'},
          {name: '张家口市', value: '130700'},
          {name: '承德市', value: '130800'},
          {name: '沧州市', value: '130900'},
          {name: '廊坊市', value: '131000'},
          {name: '衡水市', value: '131100'},
         
        ];

      // 得到结果后，关闭动画
      myChart.hideLoading();
      // 注册地图(数据放在axios返回对象的data中哦)
      echarts.registerMap(registerMapName, res.data);

      var option = {
        showLegendSymbol: true,
        //鼠标悬浮，展示相关指数tip
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontSize: 14,
                lineHeight: 22,
            },
            position: point => {
                // 固定在顶部
                return [point[0] + 50, point[1] - 20];
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            
            //    formatter: params => {
            //    return `<div style=""> ... </div>`
            //    }
            
        },
        visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
                color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)'],
            },
        },
        // 底部背景
        geo: {
            show: true,
            aspectScale: 0.85, //长宽比
            zoom: 1.22, //1.2,
            //top: '9%',
            //left: '10%',
            map: province,
            roam: false,
            itemStyle: {
                normal: {
                    areaColor: 'rgba(0,0,0,0)',
                    shadowColor: 'rgba(7,114,204, .8)',
                    shadowOffsetX: 5,
                    shadowOffsetY: 5,
                },
                emphasis: {
                    areaColor: '#00aeef',
                },
            },
        },
        series: [
          {
            name: '河北省',
            type: 'map',
            map: registerMapName,// 这个是上面注册时的名字哦，registerMap（'这个名字保持一致'）
            aspectScale: 0.85, //长宽比
            zoom: 1.22, //1.2,
            mapType: province, // 自定义扩展图表类型
            //top: '9%',
            //left: '10%',
            itemStyle: {
                normal: {
                    color: 'red',
                    areaColor: 'rgba(19,54,162, .5)',
                    borderColor: 'rgba(0,242,252,.3)',
                    borderWidth: 1,
                    shadowBlur: 7,
                    shadowColor: '#00f2fc',
                },
                emphasis: {
                    areaColor: '#4f7fff',
                    borderColor: 'rgba(0,242,252,.6)',
                    borderWidth: 2,
                    shadowBlur: 10,
                    shadowColor: '#00f2fc',
                },
            },
            label: {
                // formatter: params => `${params.name}`,
                formatter: function (params, ticket, callback) {
                    // console.log(params.name.length);
                    var allLen = params.name.length;
                    var itemLen = 6;

                    var retStr = params.name.substr(0, itemLen);
                    if(allLen > itemLen) {
                        retStr += "\n";
                        retStr += params.name.substr(itemLen);
                    }
                    return retStr;
                    // return params.name;
                },
                width: 2,
                show: true,
                position: 'insideRight',
                textStyle: {
                    fontSize: 14,
                    color: '#efefef',
                },
                emphasis: {
                    textStyle: {
                        color: '#fff',
                    },
                },
            },
            data: mapDatas,
          },
          {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            symbolSize: 18,
            effectType: 'ripple',
            legendHoverLink: false,
            showEffectOn: 'render',
            rippleEffect: {
                period: 4,
                scale: 8, //2.5,
                brushType: 'stroke',
            },
            zlevel: 1,
            itemStyle: {
                normal: {
                    // color: '#99FBFE',
                    color: '#9900ff',
                    shadowBlur: 5,
                    shadowColor: '#fff',
                },
            },
            data: [{name: city, value: cityCoord}],
            tooltip: {
                trigger: 'item',
                textStyle: {
                    fontSize: 14,
                    lineHeight: 22,
                },
                position: point => {
                    // 固定在顶部
                    return [point[0] + 50, point[1] - 20];
                },
                // 如果需要自定义 tooltip样式，需要使用formatter
                formatter: function (params, ticket, callback) {
                    return '<div style="display:flex;flex-direction:row;align-items:center;">'
                        + '<span style="background-color:#9900ff;height:10px;width:10px;border-radius:5px;margin-right:5px;"></span>'
                        + '<span>' + params.name + '</span>'
                        + '</div>';
                }
            },
          },
        ]
        
      };
      /*
      var option = {
        showLegendSymbol: true,
        //鼠标悬浮，展示相关指数tip
        tooltip: {
            trigger: 'item',
            textStyle: {
                fontSize: 14,
                lineHeight: 22,
            },
            position: point => {
                // 固定在顶部
                return [point[0] + 50, point[1] - 20];
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            
            //    formatter: params => {
            //    return `<div style=""> ... </div>`
            //    }
            
        },
        visualMap: {
            min: 0,
            max: 10,
            show: false,
            seriesIndex: 0,
            // 颜色
            inRange: {
                color: ['rgba(41,166,206, .5)', 'rgba(69,117,245, .9)'],
            },
        },
        // 底部背景
        geo: {
            show: true,
            aspectScale: 0.85, //长宽比
            zoom: 1.22, //1.2,
            //top: '9%',
            //left: '10%',
            //map: province,
            roam: false,
            itemStyle: {
                normal: {
                    areaColor: 'rgba(0,0,0,0)',
                    shadowColor: 'rgba(7,114,204, .8)',
                    shadowOffsetX: 5,
                    shadowOffsetY: 5,
                },
                emphasis: {
                    areaColor: '#00aeef',
                },
            },
        },
        series: [
        {
            name: '区域',
            type: 'map',
            aspectScale: 0.85, //长宽比
            zoom: 1.22, //1.2,
            //mapType: province, // 自定义扩展图表类型
            //top: '9%',
            //left: '10%',
            itemStyle: {
                normal: {
                    color: 'red',
                    areaColor: 'rgba(19,54,162, .5)',
                    borderColor: 'rgba(0,242,252,.3)',
                    borderWidth: 1,
                    shadowBlur: 7,
                    shadowColor: '#00f2fc',
                },
                emphasis: {
                    areaColor: '#4f7fff',
                    borderColor: 'rgba(0,242,252,.6)',
                    borderWidth: 2,
                    shadowBlur: 10,
                    shadowColor: '#00f2fc',
                },
            },
            label: {
                // formatter: params => `${params.name}`,
                formatter: function (params, ticket, callback) {
                    // console.log(params.name.length);
                    var allLen = params.name.length;
                    var itemLen = 6;

                    var retStr = params.name.substr(0, itemLen);
                    if(allLen > itemLen) {
                        retStr += "\n";
                        retStr += params.name.substr(itemLen);
                    }
                    return retStr;
                    // return params.name;
                },
                width: 2,
                show: true,
                position: 'insideRight',
                textStyle: {
                    fontSize: 14,
                    color: '#efefef',
                },
                emphasis: {
                    textStyle: {
                        color: '#fff',
                    },
                },
            },
            //data: mapDatas,
        },
        {
            type: 'effectScatter',
            coordinateSystem: 'geo',
            symbolSize: 18,
            effectType: 'ripple',
            legendHoverLink: false,
            showEffectOn: 'render',
            rippleEffect: {
                period: 4,
                scale: 8, //2.5,
                brushType: 'stroke',
            },
            zlevel: 1,
            itemStyle: {
                normal: {
                    // color: '#99FBFE',
                    color: '#9900ff',
                    shadowBlur: 5,
                    shadowColor: '#fff',
                },
            },
            //data: [{name: city, value: cityCoord}],
            tooltip: {
                trigger: 'item',
                textStyle: {
                    fontSize: 14,
                    lineHeight: 22,
                },
                position: point => {
                    // 固定在顶部
                    return [point[0] + 50, point[1] - 20];
                },
                // 如果需要自定义 tooltip样式，需要使用formatter
                formatter: function (params, ticket, callback) {
                    return '<div style="display:flex;flex-direction:row;align-items:center;">'
                        + '<span style="background-color:#9900ff;height:10px;width:10px;border-radius:5px;margin-right:5px;"></span>'
                        + '<span>' + params.name + '</span>'
                        + '</div>';
                }
            },
        },
        ],
      };
      */

      myChart.setOption(option);
    });
  }
  

};

</script>
<style lang="scss" scoped>


</style>

