<template>
    <div id="centerLeft3">
      <div class="bg-color-black">
        
        <div style="display:flex;flex-direction: column;">
          <div style="display:flex;flex-direction: row;align-items: center;">
            <span>
              <icon name="chart-line" class="text-icon"></icon>
            </span>
            <div class="d-flex">
              <span class="fs-xl text mx-2">平台列表 - {{groupNum}}</span>
            </div>
          </div>
          <div class="list-header" :style="`background-color: ${config['headerBGC']}};z-index:999;opacity:1;`" @click="transformBegin()">
            <span :style="{width:config.columnWidth[0] + 'px'}">#</span>
            <span :style="{width:config.columnWidth[1] + 'px'}">名称</span>
            <span :style="{width:config.columnWidth[2] + 'px'}">展示</span>
          </div>
        </div>
        
        <div class="d-flex jc-center body-box">
          <!-- <dv-scroll-board class="dv-scr-board" :config="config" @click="dvScrollBoardClickEvent" /> -->
          <div id="groupParentList" class="list">
            
            <div id="groupAllListItem">
              
              <div  v-for="(item, index) in config.data" :key="index">
                <div :id="'groupItem' + index" class="list-item" :style="`background-color: ${config[index % 2 === 0 ? 'evenRowBGC' : 'oddRowBGC']};`" @click="groupDvScrollBoardClickEvent(index)">
                  <span :style="{width:config.columnWidth[0] + 'px'}">{{index + 1}}</span>
                  <span :style="{width:config.columnWidth[1] + 'px'}" class="list-item-name">{{item[0]}}</span>
                  <span :style="{width:config.columnWidth[2] + 'px'}">{{item[1]}}</span>
                </div>
              </div>
  
            </div>
  
          </div>
            
        </div>
        
      </div>
    </div>
  </template>
  
  <script>
  import Transfer from "../utils/transfer.js";
  
  export default {
    data() {
      return {
        groupNum: 1,
        groupArr: [],
        endTransform: false,
        curShowIndex: 0,
        maxRowheight: 0,
        config: {
          header: ['名称', '展示'],
          data: [
            ['平台1', "√"],
            ['平台2', ""],
          ],
          //rowNum: 6, //表格行数
          headerHeight: 35,
          headerBGC: '#0f1325', //表头
          oddRowBGC: '#0f1325', //奇数行
          evenRowBGC: '#171c33', //偶数行
          index: false,
          columnWidth: [40, 170, 50],
          align: ['center', 'center', 'center'],
          indexHeader: '#',
          index: true,
          waitTime: 3000,
        }
      }
    },
    mounted() {
      this.getData();
    },
    beforeDestroy () {
        
    },
    methods: {
      //位移事件
      transformFun() {
        var showHeight = document.getElementById("groupParentList").clientHeight;
       
        var ele = document.getElementById("groupAllListItem");
        var listHeight = ele.scrollHeight;
        if(listHeight <= showHeight) return;
        
        var toScrollHeight = 0;
        for(var i = 0; i < this.curShowIndex; i++) {
          toScrollHeight += document.getElementById("groupItem" + i).clientHeight;
        }
        
        if(listHeight - toScrollHeight < showHeight) {
          toScrollHeight = listHeight - showHeight;
        }

        var top = toScrollHeight;

        //开始位移
        top = -top;
        ele.style.transform = "translateY(" + top + "px)";
        ele.style.transition = "all 1s";

      },
      /*
      //位移事件
      transformFun() {
        //向上滚动
        if(this.config.data.length <= this.config.rowNum) {
          return;
        }
        
        var ele = document.getElementById("allListItem");
        var top = document.getElementsByClassName("list-item")[0].clientHeight;
        //var top = document.getElementsByClassName("list-item")[this.curShowIndex].clientHeight;
        //if(top < this.maxRowheight) {
        //  top = this.maxRowheight;
        //} else {
        //  this.maxRowheight = top;
        //}

        if(this.curShowIndex >= (this.config.data.length - this.config.rowNum + 1) && this.curShowIndex <= (this.config.data.length  - 1)) {
          //位移到最后一页，确保展示最后一页，执行一次
          if(this.endTransform == false) {
            top *= this.config.data.length - this.config.rowNum;
            top = -top;
            ele.style.transform = "translateY(" + top + "px)";
            ele.style.transition = "all 1s";
  
            this.endTransform = true;
          }
          return;
        }
        this.endTransform = false;
        
        //开始位移
        top *= this.curShowIndex;
        top = -top;
        ele.style.transform = "translateY(" + top + "px)";
        ele.style.transition = "all 1s";
      },
      */
      //回到顶部
      transformBegin() {
        this.changeDisplay(this.config.data[0][0], 0);
        this.transformFun();
      },
      //分组列表点击事件
      groupDvScrollBoardClickEvent(ind) {
        // console.log(JSON.stringify(d));
        
        //修改展示设备
        var d = this.config.data[ind];
        var rowIndex = ind;
        var toGroup = d[0];
        this.changeDisplay(toGroup, rowIndex);
        
        this.transformFun();
      },
      changeDisplay(toGroup, toRowIndex) {
        var curIndex = 0;
        for(var i = 0; i < this.config.data.length; i++) {
          var item = this.config.data[i];
          if(item[1] == "√") {
            curIndex = i;
            break;
          }
        }
        if(curIndex == toRowIndex) return;
  
        this.config.data[curIndex][1] = ""; 
  
        if(toGroup == undefined) {
          if((curIndex + 1) >= this.config.data.length) {
            curIndex = 0;
          } else {
            curIndex++;
          }
        } else {
          curIndex = toRowIndex;
        }
        this.preShowIndex = this.curShowIndex;
        this.curShowIndex = curIndex;
  
        this.config.data[curIndex][1] = "√";
        
        this.$store.commit("setCurGroupId", this.groupArr[curIndex].groupId);
        this.$store.commit("setCurGroupName", this.groupArr[curIndex].groupName);
        
        this.config = { ...this.config };
        

        //触发分组设备列表事件
        Transfer.$emit('groupMachineList','msg-centerLeft3');
  
      },
      getData() {
        
        var globalData = this.$store.state.globalData;
        if(globalData.curGroupId == null && (globalData.curGroupName == '' || globalData.curGroupName == null)) return;
        
        let that = this;
        var resData = "?groupId=" + globalData.curGroupId + "&groupName=" + globalData.curGroupName;
        if(globalData.curGroupId == null) {
          resData = "?groupId=&groupName=" + globalData.curGroupName;
        }
        
        this.$httpApi.getGroupList(resData, function(data) {
          //console.log(JSON.stringify(data)); 
          var arr = [];
          var tmpArr = [];
          var showIndex = 0;
          if(data != null) {
            that.groupNum = data.length;
          }
          
          for(var i = 0; i < data.length; i++) {
            var item = data[i];
            var tmpObj = {};
            tmpObj.groupName = item.groupName;
            tmpObj.groupId = item.groupId;
            
            tmpArr[i] = tmpObj;
            
            var arrData = [];
            arrData[0] = item.groupName;
            arrData[1] = "";
            
            if(globalData.curGroupId == item.groupId) {
              showIndex = i;
            }
            
            arr[i] = arrData;
          }
          arr[showIndex][1] = "√";
          that.$store.commit("setCurGroupId", tmpArr[showIndex].groupId);
          that.$store.commit("setCurGroupName", tmpArr[showIndex].groupName);
          
          
          that.groupArr = tmpArr;
          that.config.data = arr;
          
          
          that.config = { ...that.config };
  
          //触发分组设备列表事件
          Transfer.$emit('groupMachineList','msg-centerLeft3');
          
        }, function(xhr) {
          console.log(xhr.responseText);
        });
        
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  $box-height: 405px;
  $box-width: 300px;
  #centerLeft3 {
    padding: 16px;
    padding-top: 20px;
    height: $box-height;
    width: $box-width;
    border-radius: 5px;
    .bg-color-black {
      height: $box-height - 30px;
      border-radius: 10px;
    }
    .text {
      color: #c3cbde;
      font-size: 15px;
    }
    .body-box {
      border-radius: 10px;
      overflow: hidden;
      .dv-scr-board {
        width: 270px;
        height: 340px;
      }
    }
  
    .list-header {
      width:100%;
      display:flex;
      flex-direction: row;
      text-align: center;
      padding:10px 0px;
      
    }
    
    .list {
      height:305px;
      display:flex;
      flex-direction:column;
      align-items:center;
    }
    
    .list-item {
      width:100%;
      display:flex;
      flex-direction: row;
      text-align: center;
      padding:12px 0px;
      
    }
    
    .list-item-name {
      white-space: normal;
      overflow-wrap: break-word
    }
  }
  </style>
  