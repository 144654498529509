
<template>
    <div id="deviceFunLight">
      <div class="bg-color-black">
        
        <div style="padding:10px;display:flex;flex-direction:row;align-items: center;">
          <span>
            <icon name="chart-line" class="text-icon"></icon>
          </span>
          <span style="margin-left:5px;margin-right:10px;">生长灯控制</span>
          <dv-decoration-3 class="dv-dec-1" />
        </div>

        <el-carousel :autoplay="false" :arrow="elCarouseArrow" :indicator-position="elCarouseIndPos" :class="elCarouselMy">
          <el-carousel-item v-for="(moduleItem, moduleIndex) in curData" :key="moduleIndex">
            <!-- <dv-decoration-7 style="margin:0;width:100%;height:30px;text-align: center;">模组{{moduleItem.id}}</dv-decoration-7> -->
            <div style="width:100%;text-align: center;" v-if="elCarouseItemNameShow">模组{{moduleItem.id}}</div>
            
            <ul>
              <li v-for="(layerItem, layerIndex) in moduleItem.data" :key="layerIndex">
                <div class="list-item display-row" :id="layerItem.id">
                  <span>第{{layerItem.id}}层</span>

                  <div  style="display:flex;flex-direction:column;">
                    <div v-for="(item, index) in layerItem.arr" :key="index" style="padding-bottom:5px;">
                      <div class="display-row">
                        <div class="display-row" style="width:60px;">
                          <!--<span>{{item.name}}</span>-->
                          <span>{{lightNameArr[index]}}</span>
                        </div>
                        <div class="display-row">
                          <div class="display-row">
                            <span style="width:90px;margin-right:5px;">光强 : {{item.value}}%</span>
                            <span @mousedown="valueSubLoopEvent(moduleIndex, layerIndex, index)" @mouseup="valueSubLoopEndEvent(moduleIndex, layerIndex, index)" class="list-item-btn" onselectstart="return false">-</span>
                            <el-slider v-model="item.value" :show-tooltip="false" @change="valueChangeEvent(moduleIndex, layerIndex, index)" class="list-item-slider"></el-slider>
                            <span @mousedown="valueAddLoopEvent(moduleIndex, layerIndex, index)" @mouseup="valueAddLoopEndEvent(moduleIndex, layerIndex, index)" class="list-item-btn" onselectstart="return false">+</span>
                          </div>
                        </div>
                        <div class="display-row" style="margin-left:30px;">
                          <span style="margin-right:10px;">开关 : </span>
                          <el-switch
                            v-model="item.switch"
                            active-color="#257dff"
                            inactive-color="#666"
                            @change="switchChangeEvent(moduleIndex, layerIndex, index)">
                          </el-switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            
          </el-carousel-item>
        </el-carousel>
        
        <div class="fun-tip" :style="{display:tipdisplay}">
            <span style="color:#d3d6dd;">{{tipText}}</span>
        </div>
      </div>

      
    </div>
</template>

<script>

import Transfer from "../utils/transfer.js";

export default {
  data() {
    return {
      //定时器
      numInternal:null,
      numInternalCount: 0, //定时计数器
      valueSubLoopTimer: null,
      valueAddLoopTimer: null,
      //设备参数
      curMachine: '',
      curOnline: 0, //0:离线，1：在线
      curStyleSize: null,  //1：智灵钻， 2：智立方， 3：智立方L， 4：单面屏风， 5：双面屏风， 6：智灵宝， 7：工程模组， 8：集装箱
      curProtocolType: null, //1：家用机协议，2：工程模组协议
      lightNameArr: ['红光', '远红光', '紫外光', '蓝光'],
      curData: [
        {
          id: 1,  //编号， 工程模组 模组号，家用机不用
          data:
          [
            {id: 1, arr: [{id: 0, value: 0, switch: false}, {id: 1, value: 0, switch: false}, 
                          {id: 2, value: 0, switch: false}, {id: 3, value: 0, switch: false}
                         ]
            },
            {id: 2, arr: [{id: 0, value: 0, switch: false}, {id: 1, value: 0, switch: false}, 
                          {id: 2, value: 0, switch: false}, {id: 3, value: 0, switch: false}
                         ]
            },
            {id: 3, arr: [{id: 0, value: 0, switch: false}, {id: 1, value: 0, switch: false}, 
                          {id: 2, value: 0, switch: false}, {id: 3, value: 0, switch: false}
                         ]
            },
            {id: 4, arr: [{id: 0, value: 0, switch: false}, {id: 1, value: 0, switch: false}, 
                          {id: 2, value: 0, switch: false}, {id: 3, value: 0, switch: false}
                         ]
            },

            /*
            {id: 1, arr: [{id: 0, name: '红光', value: 0, switch: false}, {id: 1, name: '远红光', value: 0, switch: false}, 
                          {id: 2, name: '紫外光', value: 0, switch: false}, {id: 3, name: '蓝光', value: 0, switch: false}
                         ]
            },
            {id: 2, arr: [{id: 0, name: '红光', value: 0, switch: false}, {id: 1, name: '远红光', value: 0, switch: false}, 
                          {id: 2, name: '紫外光', value: 0, switch: false}, {id: 3, name: '蓝光', value: 0, switch: false}
                         ]
            },
            {id: 3, arr: [{id: 0, name: '红光', value: 0, switch: false}, {id: 1, name: '远红光', value: 0, switch: false}, 
                          {id: 2, name: '紫外光', value: 0, switch: false}, {id: 3, name: '蓝光', value: 0, switch: false}
                         ]
            },
            {id: 4, arr: [{id: 0, name: '红光', value: 0, switch: false}, {id: 1, name: '远红光', value: 0, switch: false}, 
                          {id: 2, name: '紫外光', value: 0, switch: false}, {id: 3, name: '蓝光', value: 0, switch: false}
                         ]
            },
            */
          ]
        }
      ],
      
      tipdisplay: "none",
      tipText: '',

      //横向滚动
      elCarouseArrow: 'never', //always: 一直显示切换箭头， nerver:从不显示切换箭头
      elCarouseIndPos: 'none', //'': 默认指示器的位置， none:不显示指示器
      elCarouselMy: 'el-carousel-my-no', //'el-carousel-my',
      elCarouseItemNameShow: false  //是否显示工程模组名称
    }
  },
  components: {

  },
  mounted() {
    Transfer.$on('deviceFunLightStyle', (msg) => {
      Transfer.$off('deviceFunLightStyle');

      var layerNum = 0;
      this.curMachine = msg.curMachine;
      this.curOnline = msg.curOnline;
      this.curStyleSize = msg.curStyleSize;
      this.curProtocolType = msg.curProtocolType;
      layerNum = msg.curLayerNum;
      
      if(layerNum > 0) {
        //删除多余层数据
        if(this.curData[0].data.length > layerNum) {
            this.curData[0].data.splice(layerNum);
          }
      }
      
    });

    Transfer.$on('deviceFunLight', (msg) => {
      Transfer.$off('deviceFunLight');
      
      this.curMachine = msg.curMachine;
      this.curOnline = msg.curOnline;
      this.curStyleSize = msg.curStyleSize;
      this.curProtocolType = msg.curProtocolType;

      var layerNum = msg.curLayerNum;
      var curDataArr = [];
      curDataArr[0] = {};
      curDataArr[0] = msg.curData[0];
      
      this.curData[0].data[0].arr[0] = curDataArr[0].data[0];
      this.curData[0].data[0].arr[0].id = 0;

      if(layerNum > 0) {
        //删除多余层数据
        if(this.curData[0].data.length > layerNum) {
            this.curData[0].data.splice(layerNum);
          }
      }
      
    });
    
    
    Transfer.$on('deviceFunLightAux', (msg) => {
      Transfer.$off('deviceFunLightAux');
      //console.log(JSON.stringify(msg));

      this.curMachine = msg.curMachine;
      this.curOnline = msg.curOnline;
      this.curStyleSize = msg.curStyleSize;
      this.curProtocolType = msg.curProtocolType;

      var layerNum = 0;
      var curDataArr = [];
      
      layerNum = msg.curLayerNum;
      curDataArr[0] = {};
      curDataArr[0] = msg.curData[0];

      var tmpData = [];
      for(var i = 0; i < this.curData[0].data.length; i++) {
        tmpData[i] = {};
        tmpData[i].id = i + 1;
        tmpData[i].arr = [];
        tmpData[i].arr[0] = this.curData[0].data[i].arr[0];
        tmpData[i].arr[1] = curDataArr[0].data[i].arr[0];
        tmpData[i].arr[2] = curDataArr[0].data[i].arr[1];
        tmpData[i].arr[3] = curDataArr[0].data[i].arr[2];

        //this.curData[0].data[i].arr[1] = curDataArr[0].data[i].arr[0];
        //this.curData[0].data[i].arr[2] = curDataArr[0].data[i].arr[1];
        //this.curData[0].data[i].arr[3] = curDataArr[0].data[i].arr[2];
      }
      this.curData[0].data = tmpData;
      
    });

  },
  beforeDestroy () {
    if(this.numInternal != null) {
      clearInterval(this.numInternal);
      this.numInternal = null;
    }
    if(this.valueSubLoopTimer != null) {
      clearInterval(this.valueSubLoopTimer);
      this.valueSubLoopTimer = null;
    }
    if(this.valueAddLoopTimer != null) {
      clearInterval(this.valueAddLoopTimer);
      this.valueAddLoopTimer = null;
    }
  },
  methods: {
    
    valueSubLoopEvent(moduleIndex, layerIndex, index) {
      if(this.curData[moduleIndex].data[layerIndex].arr[index].value <= 0) return;
      this.valueSubLoopTimer = setInterval(()=>{
        this.curData[moduleIndex].data[layerIndex].arr[index].value--;
      }, 100);
    },
    valueSubLoopEndEvent(moduleIndex, layerIndex, index) {
      if(this.valueSubLoopTimer != null) {
        clearInterval(this.valueSubLoopTimer);
        this.valueSubLoopTimer = null;

        //更新
        this.valueChangeEvent(moduleIndex, layerIndex, index);
      }
      
    },

    valueAddLoopEvent(moduleIndex, layerIndex, index) {
      if(this.curData[moduleIndex].data[layerIndex].arr[index].value >= 100) return;
      
      this.valueAddLoopTimer = setInterval(()=>{
        this.curData[moduleIndex].data[layerIndex].arr[index].value++;
      }, 100);
    },
    valueAddLoopEndEvent(moduleIndex, layerIndex, index) {
      if(this.valueAddLoopTimer != null) {
        clearInterval(this.valueAddLoopTimer);
        this.valueAddLoopTimer = null;
        //更新
        this.valueChangeEvent(moduleIndex, layerIndex, index);
      }

    },

    valueChangeEvent(moduleIndex, layerIndex, index) {
      if(this.curOnline == 0) {
        this.tipShowFun(this, "设备离线，无效控制");
        return;
      }
      if(this.curData[moduleIndex].data[layerIndex].arr[index].value == 0) this.curData[moduleIndex].data[layerIndex].arr[index].switch = false;
      if(this.curData[moduleIndex].data[layerIndex].arr[index].value == 100) this.curData[moduleIndex].data[layerIndex].arr[index].switch = true;
      
      
      var layerInd = this.curData[moduleIndex].data[layerIndex].id;
      var auxInd = this.curData[moduleIndex].data[layerIndex].arr[index].id;
      if(auxInd == 0) {
        var column = "light" + layerInd + "Value";
        this.funModuleValueChange(column, this.curData[moduleIndex].data[layerIndex].arr[index].value);
      } else {
        var column = "light" + layerInd + "AuxValue" + auxInd;
        this.funModuleAuxValueChange(column, this.curData[moduleIndex].data[layerIndex].arr[index].value);
      }
      
    },
    switchChangeEvent(moduleIndex, layerIndex, index) {
      if(this.curOnline == 0) {
        this.tipShowFun(this, "设备离线，无效控制");
        return;
      }
      if(this.curData[moduleIndex].data[layerIndex].arr[index].switch == false) this.curData[moduleIndex].data[layerIndex].arr[index].value = 0;
      if(this.curData[moduleIndex].data[layerIndex].arr[index].switch == true) this.curData[moduleIndex].data[layerIndex].arr[index].value = 100;
      
      
      var layerInd = this.curData[moduleIndex].data[layerIndex].id;
      var auxInd = this.curData[moduleIndex].data[layerIndex].arr[index].id;
      if(this.curData[moduleIndex].data[layerIndex].arr[index].switch == true) {
        this.curData[moduleIndex].data[layerIndex].arr[index].value = 100;
      } else {
        this.curData[moduleIndex].data[layerIndex].arr[index].value = 0;
      }
      
      if(auxInd == 0) {
        var column = "light" + layerInd + "Value";
        this.funModuleValueChange(column, this.curData[moduleIndex].data[layerIndex].arr[index].value);
      } else {
        var column = "light" + layerInd + "AuxValue" + auxInd;
        this.funModuleAuxValueChange(column, this.curData[moduleIndex].data[layerIndex].arr[index].value);
      }
    },
    //更改光强  二代机
    funModuleValueChange(column, value) {
      let that = this;
      var rackNo = 1;
      
      var reqData = "?machineId=" + this.curMachine + "&rackNo=" + rackNo + "&column=" + column + "&value=" + value;
      this.$httpApi.updateModuleLightValue(reqData, function(data) {
          // console.log(JSON.stringify(data));
          //成功
          var msg = "";
          if(data.message == true) {
            msg = "修改成功";
          } else {
            msg = "修改失败";
          }
          that.tipShowFun(that, msg);

      }, function(xhr) {
        that.tipShowFun(that, "修改异常");
        
      });

    },
    //更改光强-辅助光强  二代机
    funModuleAuxValueChange(column, value) {
      let that = this;
      var rackNo = 1;
      
      var reqData = "?machineId=" + this.curMachine + "&rackNo=" + rackNo + "&column=" + column + "&value=" + value;
      this.$httpApi.updateModuleLightAuxValue(reqData, function(data) {
          // console.log(JSON.stringify(data));
          //成功
          var msg = "";
          if(data.message == true) {
            msg = "修改成功";
          } else {
            msg = "修改失败";
          }
          that.tipShowFun(that, msg);

      }, function(xhr) {
        that.tipShowFun(that, "修改异常");
        
      });

    },
    //消息提示
    tipShowFun(that, txt) {
      that.tipText = txt;
      that.tipdisplay = "block";
      setTimeout(() => {
        that.tipdisplay = "none";
        that.tipText = '';
      }, 1000);
    },
  }

}

</script>
<!-- <style lang="scss" scoped> -->
<style lang="scss">

#deviceFunLight {
  $box-width: 450px;
  $box-height: 900px;
  padding: 16px;
  height: $box-height;
  min-width: $box-width;
  border-radius: 10px;
  
  
  .bg-color-black {
    height: $box-height - 30px;
    border-radius: 10px;
  }
  
  .list {
    height: $box-height - 80px;
  }
  .list-item {
    padding:10px 20px;
  }
  .list-item-slider {
    width:250px;
    margin-left:15px;
    margin-right:15px;
  }
  .list-item-btn {
    height:30px;
    line-height:30px;
    width:40px;
    text-align:center;
    border:1px solid #999;
    border-radius:5px;
    
  }

  .display-row {
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    align-items: center;
  }

  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .fun-tip {
    position:absolute;
    left:40%;
    bottom:20px;
    padding:15px 50px;
    background-color: rgba(19, 25, 47, 0.6);
    border:2px solid rgb(46, 96, 153);
    border-radius:12px;
  }

  //左右滚动
  .el-carousel-my-no {
    height:100%;
  }
  .el-carousel-my {
    padding: 0px 20px;
    height:85%;
  }
  .el-carousel__container {
    height:100%;
  }
  
  .el-carousel__arrow--right {
    right: -25px;
  }
  .el-carousel__arrow--left {
    left: -30px;
  }

  [class*=" el-icon-"], [class^=el-icon-] {
    font-size:30px;
  }
  .el-carousel__indicators--horizontal {
    width:100%;
    text-align: center;
  }
}


</style>



